import { load } from "@cashfreepayments/cashfree-js";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import PageHeader from "../../components/common/PageHeader/PageHeader";
import { AUTH_TOKEN } from "../../utils/CookieConstants";
import AddOnsCard from "./components/AddOnsCard/AddOnsCard";
import CouponAppliedModal from "./components/CouponAppliedModal/CouponAppliedModal";
import CustomizationDrawer from "./components/CustomizationDrawer/CustomizationDrawer";
import PortfolioControlCard from "./components/PortfolioControlCard";
import PortfolioControlModal from "./components/PortfolioControlModal/PortfolioControlModal";
import ShootDetailsCard from "./components/ShootDetailsCard/ShootDetailsCard";
import SummaryPackageCard from "./components/SummaryPackageCard";
import "./SummaryPage.css";

const SummaryPage = () => {
  const isAuthenticated = Cookies.get(AUTH_TOKEN);
  const navigate = useNavigate();
  const bookingDate = useSelector((state) => state.orderData?.bookingDate);
  const packageData = useSelector((state) => state.orderData?.packageData);
  const serviceId = useSelector((state) => state.orderData?.serviceId);
  const timeSlot = useSelector((state) => state.orderData?.timeSlot);
  const checkoutInfo = useSelector((state) => state.orderData?.checkoutInfo);
  const priceWithVolumeDiscount = useSelector(
    (state) => state.orderData?.priceWithVolumeDiscount
  );
  const selectedCounter = useSelector(
    (state) => state.orderData?.selectedCounter
  );
  const volumeDiscount = useSelector(
    (state) => state.orderData?.volumeDiscount
  );
  const serviceData = useSelector((state) => state.orderData?.serviceData);
  const addOns = useSelector(
    (state) => state.orderData.serviceData?.frequentlyAddedServices
  );
  const [shootLocationData, setShootLocationData] = useState(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [savedAmount, setSavedAmount] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [costBreakdown, setCostBreakdown] = useState([]);
  const [cashfree, setCashfree] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const customizationOptions = serviceData?.customizationOptions || {};
  const [selectedCustomizationOptions, setSelectedCustomizationOptions] =
    useState({
      optionId: null,
      optionName: null,
    });

  const [isCustomizationModalOpen, setIsCustomizationModalOpen] =
    useState(false);

  const onCustomizationClick = () => {
    setIsCustomizationModalOpen(true);
  };

  useEffect(() => {
    if (!bookingDate || !timeSlot) {
      navigate("/select-slot");
    } else if (!serviceId) {
      navigate("/");
    }
  }, [bookingDate, timeSlot, serviceId, navigate]);

  useEffect(() => {
    const fetchDefaultAddress = async () => {
      try {
        setAddressLoading(true);
        const response = await apiClient.request(
          AppURL.getDefaultLocationUrl,
          "GET"
        );
        if (response && response.shootLocation) {
          setShootLocationData(response.shootLocation);
        }
      } catch (error) {
        console.error("Error fetching address", error);
      } finally {
        setAddressLoading(false);
      }
    };
    if (isAuthenticated) {
      fetchDefaultAddress();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const priceWithoutTax = (price) => {
      if (!price) return 0;
      const calculatedPrice = price - calculateInclusiveTax(price);
      return calculatedPrice;
    };

    const totalPrice = (price, discount, privacyConsentPrice) => {
      if (price === 0 || price === null) {
        return null;
      }

      if (discount !== 0 && discount !== null) {
        price -= discount;
      }

      if (selectedAddOns.length > 0) {
        let serviceAmount = 0;
        selectedAddOns.forEach((element) => {
          if (element.price !== null) {
            serviceAmount += element.price;
          }
        });
        price += serviceAmount;
      }

      if (privacyConsentPrice !== 0) {
        price += privacyConsentPrice;
      }

      return price;
    };

    const calculateCouponDiscount = (price, discount, discountType) => {
      if (
        discount !== 0 &&
        discount != null &&
        discountType != null &&
        price != null &&
        price > 0
      ) {
        if (discountType === "percentage") {
          price -= (price * discount) / 100;
          return price;
        }
        if (discountType === "flat") {
          price -= discount;
          return price;
        }
      }
      return 0;
    };

    const calculatePrivacyConsentPrice = (price, consent) => {
      if (consent) {
        return price * 0.1;
      }
      return 0;
    };

    // Calculate cost breakdown
    const calculateCostBreakdown = () => {
      const packagePrice = packageData?.regularPrice;
      const price = priceWithVolumeDiscount || packageData?.salePrice;
      const discount =
        parseInt(packageData?.salePrice ?? 0) -
        parseInt(packageData?.regularPrice ?? 0);
      const privacyConsentPrice = calculatePrivacyConsentPrice(
        price,
        privacyConsent
      );
      const totalDiscount = calculateCouponDiscount(
        price,
        savedAmount,
        discountType
      );
      const totalPackagePrice = totalPrice(
        price,
        totalDiscount,
        privacyConsentPrice
      );
      const tax = calculateInclusiveTax(totalPackagePrice);
      const totalPaymentAmount = totalPackagePrice;

      const breakdown = [
        { title: "Order Total", price: `₹${packagePrice?.toFixed(2)}` },
        { title: "Discount Applied", price: `₹${(-discount)?.toFixed(2)}` },
        { title: "Coupon Savings", price: `₹${(-savedAmount)?.toFixed(2)}` },
        ...selectedAddOns.map((service) => ({
          title: service.title,
          price: `₹${priceWithoutTax(service.price)?.toFixed(2)}`,
        })),
        { title: "Platform Fee", price: "Free" },
        { title: "Tax", price: `₹${tax?.toFixed(2)}` },
        { title: "Total", price: `₹${totalPaymentAmount?.toFixed(2)}` },
      ];

      if (
        checkoutInfo &&
        selectedCounter &&
        volumeDiscount &&
        priceWithVolumeDiscount
      ) {
        const volumeDiscountItem = {
          title: "Volume Discount",
          price: `${volumeDiscount} %`,
        };

        const checkoutInfoItem = {
          title: checkoutInfo,
          price: `${selectedCounter}`,
        };

        const insertIndex = 3 + selectedAddOns.length;
        breakdown.splice(insertIndex, 0, volumeDiscountItem, checkoutInfoItem);
      }

      setCostBreakdown(breakdown);
    };

    calculateCostBreakdown();
  }, [
    addOns,
    savedAmount,
    packageData,
    selectedAddOns,
    discountType,
    privacyConsent,
    checkoutInfo,
    selectedCounter,
    volumeDiscount,
    priceWithVolumeDiscount,
  ]);

  useEffect(() => {
    const initializeSDK = async () => {
      const cashfreeInstance = await load({
        mode: "sandbox",
      });
      setCashfree(cashfreeInstance);
    };
    initializeSDK();
  }, []);

  const calculateInclusiveTax = (price) => {
    const gstRate = 18.0;
    const gstAmount = price - price * (100 / (100 + gstRate));
    return gstAmount;
  };

  const handleButtonClick = () => {
    if (isAuthenticated) {
      createBooking();
    } else {
      navigate("/auth");
    }
  };

  const shootEdit = () => {
    navigate("/select-slot");
  };

  const onShootLocationClick = () => {
    navigate("/user-address");
  };

  const checkCouponCode = async () => {
    if (!couponCode) {
      toast.error("Please enter a coupon code!");
      return;
    }
    const res = await apiClient.request(AppURL.couponApplyUrl, "POST", {
      couponCode: couponCode,
      serviceId: serviceId,
    });
    if (
      res &&
      res.coupon &&
      res.coupon.couponId &&
      res.coupon.discountType &&
      res.coupon.discount
    ) {
      setCouponId(res.coupon.couponId);
      setSavedAmount(res.coupon.discount);
      setDiscountType(res.coupon.discountType);
      setIsCouponModalOpen(true);
      toast.success("Coupon applied successfully!");
    } else {
      toast.error("Invalid Coupon Code");
    }
  };

  const handleAddOnClick = (service) => {
    setSelectedAddOns((prevSelectedAddOns) => {
      if (prevSelectedAddOns.some((addOn) => addOn._id === service._id)) {
        return prevSelectedAddOns.filter((addOn) => addOn._id !== service._id);
      } else {
        return [...prevSelectedAddOns, service];
      }
    });
  };

  const removeCouponCode = () => {
    setCouponId(null);
    setCouponCode("");
    setSavedAmount(0);
    toast.success("Coupon removed successfully!");
  };

  const createBooking = async () => {
    if (
      !shootLocationData ||
      !packageData ||
      !bookingDate ||
      !timeSlot ||
      !serviceId
    ) {
      console.log("Missing required booking information:", {
        shootLocationData,
        packageData,
        bookingDate,
        timeSlot,
        serviceId,
      });
      toast.error(
        "Missing required booking information. Please check your details."
      );
      return;
    }

    let finalFilesDeadline;
    const shootDate = new Date(bookingDate);
    const deliveryDay = packageData?.deliveryDay ?? 0;

    if (packageData?.showRange) {
      const startDate = new Date(shootDate);
      startDate.setDate(startDate.getDate() + deliveryDay);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 3);
      finalFilesDeadline = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    } else {
      const finalDate = new Date(shootDate);
      finalDate.setDate(finalDate.getDate() + deliveryDay);
      finalFilesDeadline = finalDate.toLocaleDateString();
    }
    const totalAmount = parseInt(
      costBreakdown
        .find((item) => item.title === "Total")
        ?.price.replace("₹", ""),
      10
    );

    if (!finalFilesDeadline) {
      console.log("Missing required final files deadline information:", {
        finalFilesDeadline,
      });
      toast.error(
        "Missing required final files deadline information. Please check your details."
      );
      return;
    }

    if (!totalAmount) {
      console.log("Missing required total amount information:", {
        totalAmount,
      });
      toast.error(
        "Missing required total amount information. Please check your details."
      );
      return;
    }

    if (
      !shootLocationData.shootLocation ||
      !shootLocationData.locationType ||
      !shootLocationData.directionToReach ||
      !shootLocationData.coordinates ||
      !shootLocationData._id
    ) {
      console.log("Missing required shoot location information:", {
        shootLocation: shootLocationData.shootLocation,
        locationType: shootLocationData.locationType,
        directionToReach: shootLocationData.directionToReach,
        coordinates: shootLocationData.coordinates,
        locationId: shootLocationData._id,
      });
      toast.error(
        "Missing required shoot location information. Please check your details."
      );
      return;
    }

    if (!serviceData.name || !packageData.name || !packageData._id) {
      console.log("Missing required package information:", {
        serviceName: serviceData.name,
        packageName: packageData.name,
        packageId: packageData._id,
      });
      toast.error(
        "Missing required package information. Please check your details."
      );
      return;
    }

    if (!totalAmount) {
      console.log("Total amount is missing.");
      toast.error("Total amount is missing. Please check your details.");
      return;
    }

    const body = {
      locationName: shootLocationData.shootLocation,
      locationType: shootLocationData.locationType,
      locality: shootLocationData?.locality,
      directionToReach: shootLocationData.directionToReach,
      coordinates: shootLocationData.coordinates,
      locationId: shootLocationData._id,
      serviceName: serviceData.name,
      packageName: packageData.name,
      bookingDate: bookingDate,
      timeSlot: timeSlot,
      serviceId: serviceId,
      packageId: packageData._id,
      package: packageData,
      amount: totalAmount,
      costBreakdown: costBreakdown,
      finalFilesDeadline: finalFilesDeadline,
      privacyConsent: privacyConsent,
      customService: false,
      city: "Bengaluru",
    };

    if (selectedAddOns.length > 0) {
      const fas = selectedAddOns.map((addOn) => ({
        title: addOn.title,
        price: addOn.price,
      }));
      body["fas"] = fas;
    }

    if (
      selectedCustomizationOptions.optionId &&
      selectedCustomizationOptions.optionName
    ) {
      body["customizationOption"] = [selectedCustomizationOptions];
    }

    if (couponId) {
      body["couponId"] = couponId;
    }
    setPaymentLoading(true);
    const res = await apiClient.request(AppURL.bookingCreateUrl, "POST", body);
    setPaymentLoading(false);
    if (res?.orderId && res?.paymentSessionId && res?.bookingId) {
      payWithCashfree(res?.bookingId, res.paymentSessionId);
    } else {
      toast.error("Booking Failed");
    }
  };

  const payWithCashfree = async (bookingId, paymentSessionId) => {
    if (!cashfree) {
      toast.error("Payment SDK not initialized");
      return;
    }

    let checkoutOptions = {
      paymentSessionId: paymentSessionId,
      redirectTarget: "_self",
      returnUrl: `${window.location.origin}/order-status?order_id={order_id}`,
    };
    cashfree.checkout(checkoutOptions);
  };

  // const handlePaymenResponse = async (bookingId, success) => {
  //   const res = await apiClient.request(AppURL.bookingStatusUrl, "POST", {
  //     bookingId: bookingId,
  //     status: success === true ? "BOOKED" : "BOOKING_FAILED",
  //   });
  //   if (res) {
  //     toast.success("Booking Done Successfully");
  //   } else {
  //     toast.error("Booking Failed");
  //   }
  // };

  return (
    <>
      <PageHeader title="Summary" />
      <div className="container">
        <div className="summary-container">
          <div className="left">
            {packageData && (
              <SummaryPackageCard
                packageData={packageData}
                priceWithVolumeDiscount={priceWithVolumeDiscount}
              />
            )}
            <ShootDetailsCard
              shootEdit={shootEdit}
              bookingDate={bookingDate}
              timeSlot={timeSlot}
              shootLocation={shootLocationData?.shootLocation || null}
              addressLoading={addressLoading}
              onShootLocationClick={onShootLocationClick}
              isAuthenticated={isAuthenticated}
              onCustomizationClick={onCustomizationClick}
              customizationOptionName={selectedCustomizationOptions.optionName}
              isCustomizationAvailable={
                customizationOptions?.options?.length > 0
              }
            />
          </div>
          <div className="right">
            {addOns && (
              <>
                <h4 style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                  Add-ons
                </h4>
                {addOns.map((service) => (
                  <AddOnsCard
                    key={service._id}
                    title={service.title}
                    subtitle={service.description}
                    price={service.price}
                    selected={selectedAddOns.some(
                      (addOn) => addOn._id === service._id
                    )}
                    onClick={() => handleAddOnClick(service)}
                  />
                ))}
              </>
            )}
            <PortfolioControlCard
              setConsentModal={() => setIsConsentModalOpen(true)}
            />
            <Card className="my-3">
              <Card.Body>
                <Card.Title className="bold">Price Details</Card.Title>
                {isAuthenticated && (
                  <InputGroup className="mt-3 coupon-input-group">
                    <FormControl
                      placeholder="Enter coupon code"
                      aria-label="Coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      disabled={!!couponId}
                    />
                    <Button
                      variant="outline-secondary"
                      className={`apply-button ${
                        couponId ? "remove-coupon-button" : ""
                      }`}
                      onClick={couponId ? removeCouponCode : checkCouponCode}
                    >
                      {couponId ? "Remove" : "Apply"}
                    </Button>
                  </InputGroup>
                )}
                {costBreakdown.map((item, index) => (
                  <div className="price-details-text" key={index}>
                    <span>{item.title}:</span>
                    <span>{item.price}</span>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="checkout-container">
          <button
            className="chekout-button"
            onClick={handleButtonClick}
            disabled={paymentLoading}
          >
            {paymentLoading
              ? "Please wait..."
              : isAuthenticated
              ? "Proceed to Checkout"
              : "Log In/Sign Up to Proceed"}
          </button>
        </div>
      </div>
      <CouponAppliedModal
        isOpen={isCouponModalOpen}
        onClose={() => setIsCouponModalOpen(false)}
        couponCode={couponCode}
        savedAmount={savedAmount}
      />
      <PortfolioControlModal
        isOpen={isConsentModalOpen}
        onClose={() => setIsConsentModalOpen(false)}
        privacyConsent={privacyConsent}
        setPrivacyConsent={setPrivacyConsent}
      />
      {customizationOptions?.options && (
        <CustomizationDrawer
          show={isCustomizationModalOpen}
          handleClose={() => setIsCustomizationModalOpen(false)}
          customizationOptions={customizationOptions}
          setSelectedCustomizationOptions={setSelectedCustomizationOptions}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default SummaryPage;
