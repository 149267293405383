import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./CustomizationDrawer.css";

const CustomizationDrawer = ({
  show,
  handleClose,
  customizationOptions,
  setSelectedCustomizationOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectOption = (option) => {
    if (selectedOption && selectedOption._id === option._id) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleSave = () => {
    if (selectedOption) {
      setSelectedCustomizationOptions({
        optionId: selectedOption._id,
        optionName: selectedOption.optionName,
      });
    } else {
      setSelectedCustomizationOptions({
        optionId: null,
        optionName: null,
      });
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="option-modal-title">
          {customizationOptions.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{customizationOptions.description}</p>
        <div className="options-container">
          {customizationOptions.options.map((option) => (
            <div>
              <div
                key={option._id}
                className={`option-item ${
                  selectedOption && selectedOption._id === option._id
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleSelectOption(option)}
              >
                <div className="option-images">
                  {option.images.map((image, index) => (
                    <img key={index} src={image} alt={option.optionName} />
                  ))}
                </div>
              </div>
              <p className="option-name">{option.optionName}</p>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="option-save-button" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomizationDrawer;
