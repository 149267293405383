import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaChevronRight, FaTimes } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "./CancelBookingModal.css";

const CancelBookingModal = ({ open, onClose, onConfirm }) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [step, setStep] = useState(1);

  const cancellationReasons = [
    "Unsatisfactory communication by Gratif planner",
    "Decided to work with a different photographer or service provider",
    "Change in plans",
    "Other",
  ];

  useEffect(() => {
    if (!open) {
      setReason("");
      setOtherReason("");
      setStep(1);
    }
  }, [open]);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleContinue = () => {
    if (!reason) {
      toast.error("Please select a reason for cancellation.");
      return;
    }
    if (reason === "Other" && otherReason.length < 5) {
      toast.error("Please provide at least 5 characters for the other reason.");
      return;
    }
    setStep(2);
  };

  const handleConfirm = () => {
    onConfirm(reason === "Other" ? otherReason : reason);
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="cancel-booking-modal-content">
          <div className="cancel-booking-modal-header">
            <h6>Cancel Booking</h6>
            <FaTimes
              size={18}
              className="cancel-booking-modal-close-icon"
              onClick={onClose}
            />
          </div>
          {step === 1 ? (
            <>
              <div className="cancel-booking-modal-body">
                <FormControl fullWidth>
                  <InputLabel>Please select a reason</InputLabel>
                  <Select value={reason} onChange={handleReasonChange}>
                    {cancellationReasons.map((reason, index) => (
                      <MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {reason === "Other" && (
                  <TextField
                    fullWidth
                    label="Other Reason"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    style={{ marginTop: 20 }}
                  />
                )}
              </div>
              <div className="cancel-booking-modal-footer">
                <button
                  className="cancel-booking-modal-button"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="cancel-booking-modal-body">
                <h5 className="confirm-cancellation-title">
                  Confirm Cancellation
                </h5>
                <div className="refund-message">
                  <IoMdInformationCircleOutline
                    size={22}
                    className="refund-icon"
                  />{" "}
                  You'll get a full refund for this cancellation
                </div>
              </div>
              <div className="cancel-booking-modal-footer">
                <button
                  className="cancel-booking-modal-button"
                  onClick={handleConfirm}
                >
                  Continue
                </button>
              </div>
              <div className="cancel-booking-modal-cancellation-policy">
                <strong>Cancellation policy</strong>
                <FaChevronRight />
              </div>
            </>
          )}
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default CancelBookingModal;
