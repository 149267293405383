class AppURL {
  // static baseUrl = "https://test.gratif.in/";
  static baseUrl = "https://app.gratif.in/";
  // static baseUrl = "http://localhost:4455/";

  // photographer url
  static photographerUrl = "photographer";
  static contactUsUrl = "user/contact-us";
  static authUrl = "user/new-auth";
  static userRegisterUrl = "user/register";
  static bookingStatusUrl = "booking/status";
  static bookingUrl = "booking";
  static homeServiceUrl = "service/app/category";
  static bannerUrl = "banner";
  static bookingCancelUrl = "booking/cancel";
  static getServiceByCategoryUrl = "service/category";
  static serviceUrl = "service";
  static masterCalendarUrl = "master-calender/get-by-service-month-year";
  static serviceSearchUrl = "service/app/search";
  static getDefaultLocationUrl = "location/get-default";
  static couponApplyUrl = "coupon/apply";
  static bookingCreateUrl = "booking/create";
  static bookingOrderStatusUrl = "booking/verify-order";
  static locationAutocompleteUrl = "location/autocomplete";
  static checkCityAvaaibility = "user/check-city-availability";
  static userLocationUrl = "location";
  static userLocationDefaultUrl = "location/set-as-default";
  static userNotificationUrl = "notification/user";
}

export default AppURL;
