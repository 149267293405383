import React from "react";
import Carousel from "react-multi-carousel";
import "./HomeMiddleCarousel.css";

const HomeMiddleCarousel = ({ images }) => {
  const shouldScroll = images.length > 2;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: shouldScroll ? 3 : 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: shouldScroll ? 3 : 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: shouldScroll ? 3 : 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="home-middle-carousel">
      <Carousel
        responsive={responsive}
        infinite={shouldScroll}
        autoPlay={shouldScroll}
        autoPlaySpeed={3000}
        showDots={true}
        transitionDuration={500}
        arrows={true}
        // centerMode={shouldScroll}
        // centerSlidePercentage={80}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-middle-item ${
              images.length > 2 && index === 1
                ? "carousel-middle-item-large"
                : ""
            }`}
          >
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeMiddleCarousel;
