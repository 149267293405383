import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { GoBriefcase, GoHome, GoLocation } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../../../api/ApiClients";
import AppURL from "../../../api/AppUrls";
import { MAP_KEY } from "../../../utils/constants";
import { CITY } from "../../../utils/CookieConstants";
import "./UserAddressDrawer.css";

const UserAddressDrawer = ({
  show,
  handleClose,
  onSaveSuccess,
  editingLocation,
}) => {
  const [addressType, setAddressType] = useState("Home");
  const [otherAddressType, setOtherAddressType] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: 12.9716, lng: 77.5946 });
  const [markerPosition, setMarkerPosition] = useState(mapCenter);
  const [markerAddress, setMarkerAddress] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [addressSaving, setAddressSaving] = useState(false);
  const [locality, setLocality] = useState("");
  const [directionToReach, setDirectionToReach] = useState("");

  useEffect(() => {
    setSessionId(uuidv4());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setMapCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (editingLocation) {
      const locationType = editingLocation.locationType;
      if (locationType !== "Home" && locationType !== "Work") {
        setAddressType("Other");
        setOtherAddressType(locationType);
      } else {
        setAddressType(locationType);
        setOtherAddressType("");
      }
      if (
        editingLocation.coordinates &&
        editingLocation.coordinates.length === 2
      ) {
        setMapCenter({
          lat: editingLocation.coordinates[0],
          lng: editingLocation.coordinates[1],
        });
        setMarkerPosition({
          lat: editingLocation.coordinates[0],
          lng: editingLocation.coordinates[1],
        });
      }
      setMarkerAddress(editingLocation.shootLocation);
      setLocality(editingLocation.locality);
      setDirectionToReach(editingLocation.directionToReach);
    } else {
      setAddressType("Home");
      setOtherAddressType("");
      setMapCenter({ lat: 12.9716, lng: 77.5946 });
      setMarkerPosition({ lat: 12.9716, lng: 77.5946 });
      setMarkerAddress("");
      setLocality("");
      setDirectionToReach("");
    }
  }, [editingLocation]);

  const handleMapClick = (event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setMarkerPosition(newPosition);
  };

  const saveAddress = async () => {
    if (!markerAddress || !directionToReach || !addressType) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      setAddressSaving(true);
      const requestData = {
        shootLocation: markerAddress,
        locationType: addressType === "Other" ? otherAddressType : addressType,
        locality: locality || "N/A",
        directionToReach: directionToReach,
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      };

      const response = editingLocation
        ? await apiClient.request(
            `${AppURL.userLocationUrl}/${editingLocation._id}`,
            "PUT",
            requestData
          )
        : await apiClient.request(AppURL.userLocationUrl, "POST", requestData);

      if (response) {
        if (response.shootLocation && response.shootLocation.city) {
          Cookies.set(CITY, response.shootLocation.city);
          toast.success("Address saved successfully");
          onSaveSuccess(response.shootLocation); // Call the callback function
        } else if (response.message) {
          toast.error(response.message);
        } else {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error saving address", error);
    } finally {
      setAddressSaving(false);
    }
  };

  useEffect(() => {
    const fetchAddress = async (position) => {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${MAP_KEY}&sessiontoken=${sessionId}`
      );
      const data = await response.json();
      if (data.results && data.results[0]) {
        setMarkerAddress(data.results[0].formatted_address);
      } else {
        setMarkerAddress("Address not found");
      }
    };
    fetchAddress(markerPosition);
  }, [markerPosition, sessionId]);

  const handleSaveAddress = () => {
    saveAddress();
    handleClose();
  };

  const getIcon = (type) => {
    switch (type) {
      case "Home":
        return <GoHome size={20} />;
      case "Work":
        return <GoBriefcase size={20} />;
      default:
        return <GoLocation size={20} />;
    }
  };

  const handleAddressTypeClick = (type, event) => {
    event.preventDefault();
    setAddressType(type);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={addressSaving ? null : handleClose}
        size="lg"
        className="user-address-drawer"
      >
        <Modal.Header closeButton={!addressSaving}>
          <Modal.Title>
            {editingLocation ? "Edit" : "Save"} shoot location address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Address</h4>
          <div className="map-container">
            <LoadScript googleMapsApiKey={MAP_KEY}>
              <GoogleMap
                mapContainerStyle={{ height: "300px", width: "100%" }}
                center={mapCenter}
                zoom={15}
                onClick={handleMapClick}
                options={{
                  zoomControl: true,
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  rotateControl: false,
                  scaleControl: false,
                  panControl: false,
                }}
              >
                <Marker position={markerPosition} />
                {currentLocation && (
                  <Marker
                    position={currentLocation}
                    icon={{
                      url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    }}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </div>
          <Form>
            <Form.Group controlId="formMarkerAddress" className="mt-2">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" value={markerAddress} readOnly />
            </Form.Group>
            <Form.Group controlId="formLocalityArea" className="mt-2">
              <Form.Label>Locality / Area (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter locality/area"
                value={locality}
                onChange={(e) => setLocality(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAreaToReach" className="mt-2">
              <Form.Label>Destination to reach</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={200}
                placeholder="e.g. Add landmark"
                value={directionToReach}
                onChange={(e) => setDirectionToReach(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAddressType" className="mt-2">
              <Form.Label>Address Type</Form.Label>
              <div className="custom-chip-button-group">
                {["Home", "Work", "Other"].map((type) => (
                  <button
                    key={type}
                    className={`custom-chip-button ${
                      addressType === type ? "selected" : ""
                    }`}
                    onClick={(e) => handleAddressTypeClick(type, e)}
                  >
                    {getIcon(type)} {type}
                  </button>
                ))}
              </div>
              {addressType === "Other" && (
                <Form.Control
                  type="text"
                  placeholder="Enter address type"
                  value={otherAddressType}
                  onChange={(e) => setOtherAddressType(e.target.value)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="address-button"
            onClick={handleSaveAddress}
            disabled={addressSaving}
          >
            {addressSaving ? "Saving address..." : "Save address"}
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UserAddressDrawer;
