import React from "react";
import { Link } from "react-router-dom";
import { formatDateFromString } from "../../../utils/DateUtils";
import { replaceUnderscores } from "../../../utils/helper";
import "./ReservationCard.css";

const ReservationCard = ({ reservation }) => {
  const { serviceName, customId, _id, status, bookingDate, timeSlot } =
    reservation;
  const date = formatDateFromString(bookingDate);

  return (
    <Link
      to={`/reservation-details/${_id}`}
      state={{ reservation }}
      className="reservation-card-link"
    >
      <div className="reservation-card">
        <h3 className="reservation-title">{serviceName}</h3>
        <p className="reservation-detail">
          <span className="label">Booking ID:</span>
          <span className="value">{customId}</span>
        </p>
        <p className="reservation-detail">
          <span className="label">Status:</span>
          <span className="value">{replaceUnderscores(status)}</span>
        </p>
        <div className="reservation-divider"></div>
        <p className="reservation-detail">
          <span className="label">Date:</span>
          <span className="value">{date}</span>
          <span className="vertical-divider"></span>
          <span className="label">Starting at:</span>
          <span className="value">{timeSlot}</span>
        </p>
      </div>
    </Link>
  );
};

export default ReservationCard;
