import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveBookingDetails } from "../../actions/orderActions";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import PageHeader from "../../components/common/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { formatDate } from "../../utils/DateUtils";
import BookingPreferenceModal from "./components/BookingPreferenceModal";
import "./SelectSlotPage.css";

const SelectSlotPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceId = useSelector((state) => state.orderData?.serviceId);
  const shootDuration = useSelector((state) => state.orderData?.shootDuration);
  const bookingCutoffHours = useSelector(
    (state) => state.orderData?.serviceData?.bookingCutoffHours
  );
  const [masterCalendar, setMasterCalendar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!serviceId) {
      navigate("/");
    }
  }, [serviceId, navigate]);

  useEffect(() => {
    const fetchMasterCalenderData = async (month, year) => {
      try {
        const response = await apiClient.request(
          AppURL.masterCalendarUrl,
          "POST",
          {
            serviceId: serviceId,
            month: month,
            year: year,
          }
        );
        if (response && response.masterCalender) {
          setMasterCalendar(response.masterCalender);
        } else {
          console.error("Failed to fetch service details");
        }
      } catch (error) {
        console.error("Error fetching service details", error);
      } finally {
        setLoading(false);
      }
    };
    if (serviceId && activeStartDate) {
      fetchMasterCalenderData(
        activeStartDate.getMonth() + 1,
        activeStartDate.getFullYear()
      );
    }
  }, [serviceId, activeStartDate]);

  const timeSlots = [
    {
      period: "Morning",
      timeRange: "8:00 am - 12:00 pm",
      slots: ["8:00 am", "9:00 am", "10:00 am", "11:00 am", "12:00 pm"],
    },
    {
      period: "Afternoon",
      timeRange: "1:00 pm - 4:00 pm",
      slots: ["1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm"],
    },
    {
      period: "Evening",
      timeRange: "5:00 pm - 8:00 pm",
      slots: ["5:00 pm", "6:00 pm", "7:00 pm"],
    },
  ];

  const renderTileContent = ({ date, view }) => {
    if (view === "month") {
      const calendarEntry = masterCalendar.find(
        (entry) => new Date(entry.date).toDateString() === date.toDateString()
      );
      const dotColor =
        calendarEntry && calendarEntry.color ? calendarEntry.color : "#43bf57";
      return <div className="dot" style={{ backgroundColor: dotColor }}></div>;
    }
  };

  const tileClassName = ({ date, view }) => {
    if (
      view === "month" &&
      date.toDateString() === selectedDate.toDateString()
    ) {
      return "selected-date";
    }
    return null;
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const calendarEntry = masterCalendar.find(
        (entry) => new Date(entry.date).toDateString() === date.toDateString()
      );
      return calendarEntry && calendarEntry.enableStatus === false;
    }
    return false;
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const getTimeSlotStatus = (time, period) => {
    const calendarEntry = masterCalendar.find(
      (entry) =>
        new Date(entry.date).toDateString() === selectedDate.toDateString()
    );

    if (
      calendarEntry &&
      calendarEntry.timeZone &&
      calendarEntry.timeZone[period]
    ) {
      const timeSlot = calendarEntry.timeZone[period].find(
        (slot) => slot.time === time
      );
      if (timeSlot && timeSlot.enableStatus === false) {
        return false;
      }
    }

    const cutoffTime = new Date();
    cutoffTime.setHours(cutoffTime.getHours() + (bookingCutoffHours || 6));
    const [timeString, modifier] = time.split(" ");
    let [hours, minutes] = timeString.split(":").map(Number);

    if (modifier === "pm" && hours !== 12) {
      hours += 12;
    } else if (modifier === "am" && hours === 12) {
      hours = 0;
    }

    const slotDateTime = new Date(selectedDate);
    slotDateTime.setHours(hours, minutes, 0, 0);

    return slotDateTime > cutoffTime;
  };

  const calculateEndTime = (startTime, duration) => {
    if (!duration) {
      return startTime;
    }
    const [time, modifier] = startTime.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "pm" && hours !== 12) {
      hours += 12;
    } else if (modifier === "am" && hours === 12) {
      hours = 0;
    }

    hours += duration;

    const endTime = new Date();
    endTime.setHours(hours);
    endTime.setMinutes(minutes);

    const endHours = endTime.getHours();
    const endMinutes = endTime.getMinutes();
    const endModifier = endHours >= 12 ? "pm" : "am";

    const formattedEndHours = endHours % 12 || 12;
    const formattedEndMinutes = endMinutes.toString().padStart(2, "0");

    return `${formattedEndHours}:${formattedEndMinutes} ${endModifier}`;
  };

  const handleProceed = () => {
    const endTime = calculateEndTime(selectedTime, shootDuration);
    dispatch(saveBookingDetails(selectedDate, `${selectedTime} - ${endTime}`));
    navigate("/summary");
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageHeader title="Select Slot" />
      <div className="container summary-container">
        <div className="left">
          <h4 className="header">Choose date</h4>
          <Calendar
            tileContent={renderTileContent}
            tileClassName={tileClassName}
            tileDisabled={tileDisabled}
            minDate={new Date()}
            maxDate={maxDate}
            value={selectedDate}
            onClickDay={(date) => setSelectedDate(date)}
            onActiveStartDateChange={({ activeStartDate }) =>
              setActiveStartDate(activeStartDate)
            }
          />
        </div>
        <div className="right">
          <h4 className="header">Choose start time</h4>
          {timeSlots.map((slot, index) => (
            <div key={index} className="time-slot-card">
              <div className="time-slot-header">
                <h4 className="slot-period">{slot.period}</h4>
                <p>{slot.timeRange}</p>
              </div>
              <div className="time-slots">
                {slot.slots.map((time, idx) => {
                  const isDisabled = !getTimeSlotStatus(
                    time,
                    slot.period.toLowerCase()
                  );
                  return (
                    <div
                      key={idx}
                      className={`time-slot ${
                        selectedTime === time ? "selected" : ""
                      } ${isDisabled ? "disabled-date" : ""}`}
                      onClick={() => !isDisabled && setSelectedTime(time)}
                      style={{
                        textDecoration: isDisabled ? "line-through" : "none",
                      }}
                    >
                      {time}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
          <div className="shoot-info-card">
            <div className="shoot-info-card-content">
              <p>
                <span>Shoot Date:</span>
                <span>{formatDate(selectedDate)}</span>
              </p>
              <p>
                <span>Session Time:</span>
                <span>
                  {selectedTime
                    ? `${selectedTime} - ${calculateEndTime(
                        selectedTime,
                        shootDuration
                      )}`
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        className="continue-button"
        onClick={() => setIsModalOpen(true)}
        disabled={!selectedDate || !selectedTime}
      >
        Continue
      </button>
      <BookingPreferenceModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onProceed={handleProceed}
      />
    </>
  );
};

export default SelectSlotPage;
