import React from "react";
import { FaPlus } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";
import "./AddOnsCard.css";

const AddOnsCard = ({ title, subtitle, selected, price, onClick }) => {
  return (
    <div
      className={`add-ons-card ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <h6 className="service-title">
        {title} | ₹{price}
      </h6>
      <p className="service-subtitle">{subtitle}</p>
      <div className="service-icon">
        {selected ? <FiTrash /> : <FaPlus size={13} />}
      </div>
    </div>
  );
};

export default AddOnsCard;
