import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { FaChevronRight, FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserDetails } from "../../actions/userActions";
import { AUTH_TOKEN } from "../../utils/CookieConstants";
import "./UserProfilePage.css";

const UserProfilePage = () => {
  const user = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const token = Cookies.get(AUTH_TOKEN);

  useEffect(() => {
    if (token && user?._id) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [token, user]);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      Cookies.remove(AUTH_TOKEN);
      dispatch(clearUserDetails());
      window.location.href = "/";
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md={12}>
          {isUserLoggedIn && (
            <>
              <h2>
                <strong>Welcome, {user?.name}</strong>
              </h2>
              <hr />
            </>
          )}
          <ListGroup>
            {isUserLoggedIn && (
              <>
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => handleNavigation("/reservations")}
                >
                  My Orders
                  <FaChevronRight />
                </ListGroup.Item>
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => handleNavigation("/user-address")}
                >
                  Addresses
                  <FaChevronRight />
                </ListGroup.Item>
              </>
            )}
            <ListGroup.Item
              className="d-flex justify-content-between align-items-center"
              onClick={() => handleNavigation("/contact-us")}
            >
              Contact Us
              <FaChevronRight />
            </ListGroup.Item>
            <ListGroup.Item
              className="d-flex justify-content-between align-items-center"
              onClick={() => handleNavigation("/privacy")}
            >
              Privacy Policy
              <FaChevronRight />
            </ListGroup.Item>
            <ListGroup.Item
              className="d-flex justify-content-between align-items-center"
              onClick={() => handleNavigation("/about-us")}
            >
              About Us
              <FaChevronRight />
            </ListGroup.Item>
            <ListGroup.Item
              className="d-flex justify-content-between align-items-center"
              onClick={() => handleNavigation("/terms")}
            >
              Terms of Service
              <FaChevronRight />
            </ListGroup.Item>
            <hr />
            {isUserLoggedIn ? (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-center text-danger"
                onClick={handleLogout}
              >
                Logout
                <FaSignOutAlt />
              </ListGroup.Item>
            ) : (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-center"
                onClick={() => handleNavigation("/auth")}
              >
                Login / Signup
                <FaChevronRight />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfilePage;
