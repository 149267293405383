import React from "react";
import { formatDate } from "../../../../utils/DateUtils";
import "./ShootDetailsCard.css";

const ShootDetailsCard = ({
  shootEdit,
  bookingDate,
  timeSlot,
  shootLocation,
  onShootLocationClick,
  addressLoading,
  isAuthenticated,
  onCustomizationClick,
  customizationOptionName,
  isCustomizationAvailable,
}) => {
  return (
    <div className="shoot-details-card">
      <div className="details">
        <div className="detail">
          <span className="label">Slot:</span>
          <span
            className="edit-text"
            style={{ cursor: "pointer" }}
            onClick={shootEdit}
          >
            Edit
          </span>
        </div>
        <div className="detail">
          <span className="label">Shoot date:</span>
          <span className="value">
            {bookingDate ? formatDate(bookingDate) : "N/A"}
          </span>
        </div>
        <div className="detail">
          <span className="label">Session time:</span>
          <span className="value">{timeSlot ? timeSlot : "N/A"}</span>
        </div>
        {isAuthenticated && (
          <>
            <div className="divider"></div>
            <div className="detail">
              <span className="label">Shoot Location:</span>
              <span
                className="edit-text"
                style={{ cursor: "pointer" }}
                onClick={addressLoading ? null : onShootLocationClick}
              >
                {addressLoading ? "Loading" : shootLocation ? "Edit" : "Add"}
              </span>
            </div>
            {shootLocation && (
              <div className="location-detail">{shootLocation || "N/A"}</div>
            )}
          </>
        )}
        {isCustomizationAvailable && (
          <>
            <div className="divider"></div>
            <div className="detail">
              <span className="label">Customization:</span>
              <span
                className="edit-text"
                style={{ cursor: "pointer" }}
                onClick={onCustomizationClick}
              >
                {customizationOptionName ? "Edit" : "Add"}
              </span>
            </div>
            {customizationOptionName && (
              <div className="location-detail">{customizationOptionName}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShootDetailsCard;
