import { USER_CLEAR_DETAILS, USER_SAVE_DETAILS } from "../types/userTypes";

const initialState = {
  _id: null,
  name: null,
  email: null,
  phone: null,
  countryCode: null,
  createdAt: null,
  emailVerified: null,
  deleted: null,
  firebase_tkn: null,
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case USER_SAVE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case USER_CLEAR_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default userReducers;
