import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import Footer from "../../components/common/Footer/Footer";
import NewHeader from "../../components/common/NewHeader/NewHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { CITY } from "../../utils/CookieConstants";
import HomeCarousel from "./components/HomeCarousel/HomeCarousel";
import HomeMiddleCarousel from "./components/HomeMiddleCarousel/HomeMiddleCarousel";
import SectionTitle from "./components/SectionTitle/SectionTitle";
import "./HomeNewPage.css";

const HomeNewPage = () => {
  const [banners, setBanners] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState("Bengaluru");

  useEffect(() => {
    const city = Cookies.get(CITY);
    if (city) {
      setCity(city);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerResponse = await apiClient.request(
          `${AppURL.bannerUrl}?city=${city}`,
          "GET"
        );
        const serviceResponse = await apiClient.request(
          `${AppURL.homeServiceUrl}?city=${city}`,
          "GET"
        );

        if (
          bannerResponse.message === "success" &&
          serviceResponse.message === "success"
        ) {
          setBanners(bannerResponse.banners);
          setServiceCategories(serviceResponse.serviceCategories);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [city]);

  if (loading) {
    return (
      <>
        <NewHeader />
        <LoadingSpinner />
      </>
    );
  }

  if (!loading && serviceCategories.length === 0) {
    return (
      <>
        <NewHeader />
        <div className="home-empty-state">
          <h2>No Services Available</h2>
          <p>
            Currently, there are no services available in your city. Please
            check back later.
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <NewHeader />
      <div className="container">
        {serviceCategories.length > 0 && (
          <>
            <SectionTitle
              title={serviceCategories[0].name}
              path={`/services/${serviceCategories[0]._id}`}
              services={serviceCategories[0].services}
            />
            <HomeCarousel services={serviceCategories[0].services} />
          </>
        )}
        {banners.length > 0 && (
          <HomeMiddleCarousel
            images={banners.map((banner) => banner.imageLink)}
          />
        )}
        {serviceCategories.length > 1 && (
          <>
            <SectionTitle
              title={serviceCategories[1].name}
              path={`/services/${serviceCategories[1]._id}`}
              services={serviceCategories[1].services}
            />
            <HomeCarousel services={serviceCategories[1].services} />
          </>
        )}
      </div>
      <div style={{ marginTop: "5rem" }}></div>
      <Footer />
    </>
  );
};

export default HomeNewPage;
