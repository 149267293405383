import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

const SummaryPackageCard = ({ packageData, priceWithVolumeDiscount }) => {
  const iconSize = 13;
  const textStyle = { color: "white" };

  return (
    <div
      className="summary-package-card"
      style={{ backgroundColor: packageData.packageColor }}
    >
      <h5 className="title" style={textStyle}>
        {packageData.name}
      </h5>
      <p className="price" style={textStyle}>
        ₹
        {priceWithVolumeDiscount
          ? priceWithVolumeDiscount
          : packageData.salePrice}{" "}
        • {packageData.shootDuration} hours session
      </p>
      <div className="divider"></div>
      {packageData.packageDetails.map((detail) => (
        <p className="feature" key={detail._id} style={textStyle}>
          {detail.available ? (
            <FaCheck
              size={iconSize}
              className="icon"
              style={{ color: "var(--light-orange-color)" }}
            />
          ) : (
            <FaTimes
              size={iconSize}
              className="icon"
              style={{ color: "var(--light-orange-color)" }}
            />
          )}
          {detail.name}
        </p>
      ))}
    </div>
  );
};

export default SummaryPackageCard;
