import PropTypes from "prop-types";
import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "./HomeCarousel.css";

const HomeCarousel = ({ services }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {services.map((service, index) => (
        <div key={index} className="home-carousel-item">
          <Link to={`/service-details/${service._id}`} state={{ service }}>
            <img
              src={service.thumbnailImage}
              alt={`home-carousel-item-${index}`}
            />
            <div className="home-carousel-item-name">{service.name}</div>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

HomeCarousel.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      thumbnailImage: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HomeCarousel;
