const formatDate = (date) => {
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const formatDateFromString = (dateString) => {
  const date = new Date(dateString);
  return formatDate(date);
};

export { formatDate, formatDateFromString };
