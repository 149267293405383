import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { saveUserDetails } from "../../../actions/userActions";
import apiClient from "../../../api/ApiClients";
import AppURL from "../../../api/AppUrls";
import googleIcon from "../../../assets/images/google-icon.png";
import {
  auth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "../../../configs/firebaseConfig";
import { AUTH_TOKEN } from "../../../utils/CookieConstants";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import "../css/AuthStyles.css";

const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userData?._id);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpLoading, setOtpLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [view, setView] = useState("login"); // login, otp, signup
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const provider = new GoogleAuthProvider();
  const otpRefs = useRef([]);
  const token = Cookies.get(AUTH_TOKEN);
  const [isEmailPrefilled, setIsEmailPrefilled] = useState(false);

  useEffect(() => {
    if (token && userId) {
      navigate("/", { replace: true });
    }
  }, [token, navigate, userId]);

  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextMenu);
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {},
      }
    );
  };

  const handleSendOtp = () => {
    setOtpLoading(true);
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `+91${phoneNumber}`;
    signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpLoading(false);
        setView("otp");
      })
      .catch((error) => {
        console.error("Error during OTP send", error);
        toast.error("Error during OTP send");
        setOtpLoading(false);
      });
  };

  const handleVerifyOtp = () => {
    setOtpLoading(true);
    const confirmationResult = window.confirmationResult;
    const otpCode = otp.join("");
    confirmationResult
      .confirm(otpCode)
      .then(async (result) => {
        const accessToken = await result.user.getIdToken();
        const res = await apiClient.request(AppURL.authUrl, "POST", {
          token: accessToken,
        });
        handleResponse(res, "phone");
        setOtpLoading(false);
      })
      .catch((error) => {
        console.error("Error during OTP verification", error);
        toast.error("Error during OTP verification");
        setOtpLoading(false);
      });
  };

  const handleGoogleLogin = () => {
    setGoogleLoading(true);

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const accessToken = await result.user.getIdToken();
        const email = result.user.email;
        const res = await apiClient.request(AppURL.authUrl, "POST", {
          token: accessToken,
        });
        handleResponse(res, "google", email);
        setGoogleLoading(false);
        setIsEmailPrefilled(true);
      })
      .catch((error) => {
        console.error("Error during Google login", error);
        toast.error("Error during Google login");
        setGoogleLoading(false);
      });
  };

  const handleResponse = (res, source, email = "") => {
    if (res) {
      if (res.token && res.user) {
        dispatch(saveUserDetails(res.user));
        Cookies.set(AUTH_TOKEN, res.token);
      } else if (res.userNotFound) {
        setPhoneNumber(source === "phone" ? phoneNumber : "");
        setEmail(source === "google" ? email : "");
        setView("signup");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleSignUp = async () => {
    const res = await apiClient.request(AppURL.userRegisterUrl, "POST", {
      phone: phoneNumber,
      email,
      name,
      source: email ? "google" : "",
      countryCode: "+91",
    });
    if (res && res.token && res.user) {
      dispatch(saveUserDetails(res.user));
      Cookies.set(AUTH_TOKEN, res.token);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  return (
    <div className="auth-page">
      <ToastContainer />
      <div className="auth-left-side">
        <AuthLeftSide />
      </div>
      <div className="auth-right-side">
        {view === "login" && (
          <>
            <h2>Log in or Sign up</h2>
            <input
              type="text"
              placeholder="Phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <p>
              We’ll text to confirm your number. Standard message and data rates
              apply.
            </p>
            <button
              className="auth-continue-button"
              onClick={handleSendOtp}
              disabled={otpLoading || googleLoading}
            >
              {otpLoading ? "Sending OTP..." : "Continue"}
            </button>
            <div id="recaptcha-container"></div>
            <div className="auth-divider">
              <span className="auth-divider-line"></span>
              <span className="auth-divider-text">Or</span>
              <span className="auth-divider-line"></span>
            </div>
            <button
              className="google-continue-button"
              onClick={handleGoogleLogin}
              disabled={otpLoading || googleLoading}
            >
              <img src={googleIcon} alt="Google Icon" className="google-icon" />
              {googleLoading ? "Logging in..." : "Continue with Google"}
            </button>
          </>
        )}
        {view === "otp" && (
          <>
            <h2>Enter OTP</h2>
            <p className="otp-instruction">
              Enter the 6-digit code we’ve just sent to +91{phoneNumber}
            </p>
            <div className="auth-otp-input">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  ref={(el) => (otpRefs.current[index] = el)}
                />
              ))}
            </div>
            <p className="resend-text">
              Didn’t get a text?{"  "}
              <a href="/" className="resend-link" onClick={handleSendOtp}>
                Send again
              </a>
            </p>
            <button
              className="auth-continue-button"
              onClick={handleVerifyOtp}
              disabled={otpLoading}
            >
              {otpLoading ? "Verifying OTP..." : "Continue"}
            </button>
          </>
        )}
        {view === "signup" && (
          <>
            <h2>Sign up</h2>
            <input
              type="text"
              placeholder="Phone number"
              maxLength={10}
              value={phoneNumber}
              readOnly={!isEmailPrefilled}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              readOnly={isEmailPrefilled}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p>We’ll email you booking confirmations and receipts.</p>
            <p>
              By selecting Agree and continue, I agree to Gratif’s{" "}
              <a href="/">Terms of Service</a>,{" "}
              <a href="/">Payments Terms of Service</a>,{" "}
              <a href="/">Nondiscrimination Policy</a> and acknowledge the{" "}
              <a href="/">Privacy Policy</a>.
            </p>
            <button className="auth-continue-button" onClick={handleSignUp}>
              Agree and continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
