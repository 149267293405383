import React, { useEffect, useState } from "react";
import { SlArrowLeft } from "react-icons/sl";
import Carousel from "react-multi-carousel";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  saveOrderSession,
  saveVolumeDiscount,
} from "../../actions/orderActions";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import taxLogo from "../../assets/images/ic_tax.png";
import NewHeader from "../../components/common/NewHeader/NewHeader";
import ContactUsModal from "../../components/ContactUsModel/ContactUsModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ImageCarouselModal from "../../components/Modals/ImageCarouselModal/ImageCarouselModal";
import PackageCard from "./components/PackageCard";
import ServiceCounterContainer from "./components/ServiceCounterContainer/ServiceCounterContainer";
import "./ServiceDetailsPage.css";

const ServiceDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(
    location?.state?.service
  );
  const [loading, setLoading] = useState(!serviceDetails);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await apiClient.request(
          `${AppURL.serviceUrl}/${id}`,
          "GET"
        );
        if (response && response.service) {
          setServiceDetails(response.service);
          setSelectedFilter(response.service.filters?.[0] || "");
        } else {
          console.error("Failed to fetch service details");
        }
      } catch (error) {
        console.error("Error fetching service details", error);
      } finally {
        setLoading(false);
      }
    };

    if (!serviceDetails && id) {
      fetchServiceDetails();
    } else if (serviceDetails) {
      setSelectedFilter(serviceDetails.filters?.[0] || "");
    }
  }, [id, serviceDetails]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageClick = (index) => {
    setInitialImageIndex(index);
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const calculateDiscountPercentage = ({
    selectedNumber,
    minNumber,
    maxNumber,
    maxDiscount,
  }) => {
    if (selectedNumber === minNumber) {
      return 0;
    }
    const numberOfSteps = maxNumber - 1;
    const discountPercentage =
      ((selectedNumber - 1) * maxDiscount) / numberOfSteps;
    return Math.min(Math.max(discountPercentage, 0), maxDiscount);
  };

  const handleValueChanged = (number) => {
    setSelectedNumber(number);
    const discount = calculateDiscountPercentage({
      selectedNumber: number,
      minNumber: serviceDetails.counter.minNumber,
      maxNumber: serviceDetails.counter.maxNumber,
      maxDiscount: serviceDetails.counter.maxDiscount,
    });
    setDiscountPercentage(discount);
  };

  const calculateSalePrice = ({
    price,
    minNumber,
    selectedNumber,
    discountPercentage,
  }) => {
    if (price == null) {
      return null;
    }
    if (selectedNumber === minNumber) {
      return price;
    }
    const discountedPricePerPerson = price * (1 - discountPercentage / 100);
    const finalPrice = Math.round(discountedPricePerPerson * selectedNumber);
    return finalPrice;
  };

  if (loading) {
    return (
      <>
        <NewHeader />
        <LoadingSpinner />
      </>
    );
  }

  if (!serviceDetails) {
    return (
      <>
        <NewHeader />
        <div>No service details found</div>
      </>
    );
  }

  const { description, assets, packages, filters } = serviceDetails;

  const filteredPackages = selectedFilter
    ? packages.filter((pkg) => pkg.filter === selectedFilter)
    : packages;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handlePackageContinue = async (packageData, shootDuration) => {
    if (id && packageData) {
      dispatch(
        saveOrderSession(id, packageData, shootDuration, serviceDetails)
      );

      if (
        isCounterValid(serviceDetails?.counter) &&
        selectedNumber !== serviceDetails.counter.minNumber
      ) {
        dispatch(
          saveVolumeDiscount(
            serviceDetails.counter.checkoutInfo,
            selectedNumber,
            discountPercentage?.toFixed(2),
            calculateSalePrice({
              price: packageData.salePrice,
              minNumber: serviceDetails.counter.minNumber,
              selectedNumber:
                selectedNumber || serviceDetails.counter.minNumber,
              discountPercentage: discountPercentage || 0,
            })
          )
        );
      }

      navigate("/select-slot");
    } else {
      toast.error("Something went wrong");
    }
  };

  const isCounterValid = (counter) => {
    return (
      counter &&
      counter.title &&
      counter.minNumber !== undefined &&
      counter.maxNumber !== undefined &&
      counter.maxDiscount !== undefined &&
      counter.discountInfo &&
      counter.checkoutInfo &&
      counter._id
    );
  };

  return (
    <>
      <NewHeader />
      <div className="container">
        <Link to="/">
          <div className="service-list-header">
            <SlArrowLeft className="service-list-chevron-icon" size={18} />
            <h4 className="service-list-title">Service Details</h4>
          </div>
        </Link>
        <Carousel responsive={responsive}>
          {assets.map((image, index) => (
            <div
              key={index}
              className="service-details-carousel-item"
              onClick={() => handleImageClick(index)}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                className="service-details-carousel-image"
              />
            </div>
          ))}
        </Carousel>
        {isCounterValid(serviceDetails?.counter) && (
          <ServiceCounterContainer
            title={serviceDetails.counter.title}
            selectedNumber={selectedNumber}
            minNumber={serviceDetails.counter.minNumber}
            discountPercentage={discountPercentage}
            totalCount={serviceDetails.counter.maxNumber}
            discountInfo={serviceDetails.counter.discountInfo}
            onValueChanged={handleValueChanged}
          />
        )}
        <div className="service-details-description">{description}</div>
        {filters && filters.length > 0 && (
          <div className="filter-chips-container">
            {filters.map((filter, index) => (
              <div
                key={index}
                className={`filter-chip ${
                  selectedFilter === filter ? "selected" : ""
                }`}
                onClick={() => setSelectedFilter(filter)}
              >
                {filter}
              </div>
            ))}
          </div>
        )}
        <div className="package-cards-container">
          {filteredPackages
            .filter((pkg) => pkg.enabled)
            .map((pkg, index) => {
              const salePrice = isCounterValid(serviceDetails?.counter)
                ? calculateSalePrice({
                    price: pkg.salePrice,
                    minNumber: serviceDetails.counter.minNumber,
                    selectedNumber:
                      selectedNumber || serviceDetails.counter.minNumber,
                    discountPercentage: discountPercentage || 0,
                  })
                : pkg.salePrice;

              const regularPrice =
                isCounterValid(serviceDetails?.counter) && selectedNumber
                  ? pkg.regularPrice * selectedNumber
                  : pkg.regularPrice;

              return (
                <PackageCard
                  key={index}
                  packageColor={pkg.packageColor}
                  name={pkg.name}
                  packageDetails={pkg.packageDetails}
                  salePrice={salePrice}
                  regularPrice={regularPrice}
                  shootDuration={pkg.shootDuration}
                  handlePackageContinue={() =>
                    handlePackageContinue(pkg, pkg.shootDuration)
                  }
                />
              );
            })}
        </div>
        <p className="service-details-footer">
          <img src={taxLogo} alt="Tax Icon" className="tax-icon" />
          All prices include 18% GST and setting up charges
        </p>
        <div className="service-details-divider"></div>
        <div className="customized-message">
          <strong>Need something customised?</strong>
          <p>Our in house planners can help.</p>
          <button className="send-message-button" onClick={handleOpenModal}>
            Send a message
          </button>
        </div>
      </div>
      <ContactUsModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <ImageCarouselModal
        isOpen={isImageModalOpen}
        images={assets}
        onClose={handleCloseImageModal}
        initialIndex={initialImageIndex}
      />
      <ToastContainer />
    </>
  );
};

export default ServiceDetailsPage;
