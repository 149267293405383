import React, { useEffect, useState } from "react";
import { SlArrowLeft } from "react-icons/sl";
import { Link, useLocation, useParams } from "react-router-dom";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import NewHeader from "../../components/common/NewHeader/NewHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ServiceCard from "./components/ServiceCard";
import "./ServiceList.css";

const ServiceList = () => {
  const location = useLocation();
  const { id } = useParams();
  const [services, setServices] = useState(location?.state?.services || []);
  const [loading, setLoading] = useState(!location?.state?.services);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await apiClient.request(
          `${AppURL.getServiceByCategoryUrl}/${id}`,
          "GET"
        );
        if (response && response.services) {
          setServices(response.services);
        } else {
          console.error("Failed to fetch services");
        }
      } catch (error) {
        console.error("Error fetching services", error);
      } finally {
        setLoading(false);
      }
    };

    if (!services.length && id) {
      fetchServices();
    }
  }, [id, services.length]);

  if (loading) {
    return (
      <>
        <NewHeader />
        <LoadingSpinner />
      </>
    );
  }

  if (!services.length) {
    return (
      <>
        <NewHeader />
        <div className="container">No services found</div>
      </>
    );
  }

  return (
    <>
      <NewHeader />
      <div className="container">
        <Link to="/">
          <div className="service-list-header">
            <SlArrowLeft className="service-list-chevron-icon" size={18} />
            <h4 className="service-list-title">Home</h4>
          </div>
        </Link>
        {services.map((service) => (
          <ServiceCard
            key={service._id}
            title={service.name}
            subtitle={service.description}
            price={`₹${service.startingFrom}`}
            thumbnailImage={service.thumbnailImage}
            service={service}
            path={`/service-details/${service._id}`}
          />
        ))}
      </div>
    </>
  );
};

export default ServiceList;
