import React from "react";
import { FaTimes } from "react-icons/fa";
import "./PortfolioControlModal.css";

const PortfolioControlModal = ({
  isOpen,
  onClose,
  privacyConsent,
  setPrivacyConsent,
}) => {
  if (!isOpen) return null;

  return (
    <div className="portfolio-modal-overlay">
      <div className="portfolio-modal-content">
        <div className="portfolio-modal-header">
          <h5 style={{ marginBottom: "0px" }}>Portfolio inclusion</h5>
          <FaTimes
            size={22}
            className="portfolio-close-icon"
            onClick={onClose}
          />
        </div>
        <hr className="portfolio-modal-divider" />
        <div className="portfolio-inclusion-container">
          <div>
            <span style={{ fontWeight: "bold" }}>Portfolio inclusion</span>
            <div>10% discounted</div>
          </div>
          <label className="switch">
            <input
              type="checkbox"
              checked={privacyConsent}
              onChange={() => setPrivacyConsent(!privacyConsent)}
            />
            <span className="slider round"></span>
          </label>
          <div>
            <span style={{ fontWeight: "bold" }}>Keep private</span>
            <div>+ 10% on order price</div>
          </div>
        </div>
        <hr className="portfolio-modal-divider" />
        <div className="portfolio-modal-description">
          Some of your exceptional photos or videos may be chosen to be included
          in our exclusive portfolio, highliting the quality of our work for
          future clients. None of these materials will be sold or used
          commercially
        </div>
        <div className="portfolio-modal-description mt-2">
          If you choose to keep your work completely private and exclude it from
          our portfolio, there will be a 10% surcharge on the original price.
          This surcharge helps us compensate for the limited marketing
          opportunities and inability to showcase the full potential of the work
          our partners create.
        </div>
      </div>
    </div>
  );
};

export default PortfolioControlModal;
