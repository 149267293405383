import React from "react";
import { MdClose } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "./ImageCarouselModal.css";

const ImageCarouselModal = ({ isOpen, images, onClose, initialIndex }) => {
  if (!isOpen) return null;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="image-carousel-modal-overlay">
      <MdClose
        className="image-carousel-modal-close-icon"
        size={34}
        onClick={onClose}
      />
      <Carousel
        responsive={responsive}
        ssr
        infinite
        initialSlide={initialIndex}
      >
        {images.map((image, index) => (
          <div key={index} className="image-carousel-modal-item">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="image-carousel-modal-full-image"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarouselModal;
