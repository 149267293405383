import { USER_CLEAR_DETAILS, USER_SAVE_DETAILS } from "../types/userTypes";

export const saveUserDetails = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER_SAVE_DETAILS,
      payload: user,
    });
  };
};

export const clearUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: USER_CLEAR_DETAILS,
    });
  };
};
