import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import NewHeader from "../../components/common/NewHeader/NewHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const UserNotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchNotifications = async (page) => {
    try {
      const response = await apiClient.request(
        `${AppURL.userNotificationUrl}?page=${page}`,
        "GET"
      );
      if (response && response.notifications) {
        setNotifications(response.notifications);
        if (response.totalPages) {
          setTotalPages(response.totalPages);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching service details", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <>
        <NewHeader />
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <NewHeader />
      <Container>
        <Row>
          <Col>
            <h3 style={{ margin: "40px 0" }}>User Notifications</h3>
            {notifications.map((notification, index) => (
              <Card key={index} className="mb-3">
                <Card.Header
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{notification.title}</span>
                  <span>
                    {new Date(notification.createdAt).toLocaleDateString(
                      "en-GB"
                    )}
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>{notification.body}</Card.Text>
                </Card.Body>
              </Card>
            ))}
            <div className="mt-4 mb-4 d-flex align-items-center justify-content-end pagination-controls">
              <Button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              <span className="mx-2 d-flex justify-content-center">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default UserNotificationPage;
