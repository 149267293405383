import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./ContactUsModal.css";

const ContactUsModal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState("support");

  if (!isOpen) return null;

  return (
    <div className="contact-us-modal-overlay">
      <div className="contact-us-modal-content">
        <div className="contact-us-modal-header">
          <h4>Contact Us</h4>
          <FaTimes
            size={22}
            className="contact-us-modal-close-icon"
            onClick={onClose}
          />
        </div>
        <div className="contact-us-modal-body">
          <div className="contact-us-radio-group">
            <label>
              <input
                type="radio"
                name="contactReason"
                value="support"
                checked={selectedOption === "support"}
                onChange={() => setSelectedOption("support")}
              />
              Contact Support
            </label>
            <label>
              <input
                type="radio"
                name="contactReason"
                value="suggestion"
                checked={selectedOption === "suggestion"}
                onChange={() => setSelectedOption("suggestion")}
              />
              Suggestion
            </label>
          </div>
          <textarea
            className="contact-us-modal-textarea"
            placeholder="Tell us about your concern or issue."
          />
          <button className="contact-us-modal-submit-button">Submit</button>
          <hr className="contact-us-modal-divider" />
          <div className="contact-us-modal-footer">
            <p>
              Our dedicated customer service team is here to assist you during
              the following business hours:
            </p>
            <p>
              All days: 9:00 AM to 6:00 PM (IST) <br />
              Government holidays: Closed <br />
              Outside of these hours, you can still contact us via email at{" "}
              <a href="mailto:cs@gratif.in">cs@gratif.in</a>, and we will get
              back to you as soon as possible during the next business day.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
