// orderReducers.js
import {
  ORDER_SAVE_BOOKING_DETAILS,
  ORDER_SAVE_VOLUME_DISCOUNT,
  ORDER_SESSION_SAVE_DETAILS,
} from "../types/orderTypes";

const initialState = {
  serviceId: null,
  serviceData: null,
  packageData: null,
  shootDuration: null,
  bookingDate: null,
  timeSlot: null,
  checkoutInfo: null,
  selectedCounter: null,
  volumeDiscount: null,
  priceWithVolumeDiscount: null,
};

const orderReducers = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SESSION_SAVE_DETAILS:
      return {
        ...state,
        serviceId: action.payload.serviceId,
        serviceData: action.payload.serviceData,
        packageData: action.payload.packageData,
        shootDuration: action.payload.shootDuration,
      };
    case ORDER_SAVE_BOOKING_DETAILS:
      return {
        ...state,
        bookingDate: action.payload.bookingDate,
        timeSlot: action.payload.timeSlot,
      };
    case ORDER_SAVE_VOLUME_DISCOUNT:
      return {
        ...state,
        checkoutInfo: action.payload.checkoutInfo,
        selectedCounter: action.payload.selectedCounter,
        volumeDiscount: action.payload.volumeDiscount,
        priceWithVolumeDiscount: action.payload.priceWithVolumeDiscount,
      };
    default:
      return state;
  }
};

export default orderReducers;
