import React from "react";
import { Link } from "react-router-dom";

import "./ServiceCard.css";

const ServiceCard = ({
  title,
  subtitle,
  price,
  thumbnailImage,
  service,
  path,
}) => {
  return (
    <div className="service-card">
      <img src={thumbnailImage} alt="Service" className="service-image" />
      <div className="service-info">
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <p className="price-text">
          Starting at <span className="price">{price}</span>
        </p>
        <Link to={path} state={{ service }} className="btn btn-dark">
          Explore Packages
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
