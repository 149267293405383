import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaBell, FaChevronDown, FaSearch } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/gratif_logo.png";
import profilePlaceholder from "../../../assets/images/profile_placeholder_2.png";
import { AUTH_TOKEN, CITY } from "../../../utils/CookieConstants";
import LeftHomeDrawer from "./components/LeftHomeDrawer";
import "./NewHeader.css";

const NewHeader = () => {
  const [token, setToken] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [city, setCity] = useState("Bengaluru");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get(AUTH_TOKEN);
    setToken(token);
    const city = Cookies.get(CITY);
    if (city) {
      setCity(city);
    }
  }, []);

  const homeNavigate = () => {
    navigate("/");
  };

  const handleSearchClick = () => {
    navigate("/search-service");
  };

  const handleProfileClick = () => {
    navigate("/user-profile");
  };

  const handleNotificationClick = () => {
    navigate("/user-notifications");
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <header className="new-header">
        <div className="container d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <img
              src={logo}
              alt="Gratif Logo"
              className="logo"
              onClick={homeNavigate}
            />
            {location.pathname === "/" && (
              <div className="search-box d-flex align-items-center position-relative ml-3">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search for"
                  className="form-control pl-5"
                  onClick={handleSearchClick}
                  readOnly
                />
              </div>
            )}
          </div>

          <div className="ml-auto d-flex align-items-center">
            {location.pathname === "/" && (
              <Link to="/home-partner" className="btn btn-link mx-3">
                Become a partner
              </Link>
            )}
            <button
              className="btn btn-link d-flex align-items-center mx-3"
              onClick={handleDrawerToggle}
            >
              {city}
              <FaChevronDown className="ml-2" />
            </button>
            {token && (
              <FaBell
                size={18}
                className="mx-3"
                style={{ cursor: "pointer" }}
                onClick={handleNotificationClick}
              />
            )}
            <img
              src={profilePlaceholder}
              alt="Profile"
              className="mx-3"
              style={{ width: "2.5rem", height: "2.5rem", cursor: "pointer" }}
              onClick={handleProfileClick}
            />
          </div>
        </div>
      </header>
      {isDrawerOpen && (
        <LeftHomeDrawer isOpen={isDrawerOpen} onClose={handleDrawerToggle} />
      )}
    </>
  );
};

export default NewHeader;
