import Cookies from "js-cookie";
import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router";
import AboutUs from "../pages/AboutUs/AboutUs";
import AccountDeletionPage from "../pages/AccountDeletionPage/AccountDeletionPage";
import AuthPage from "../pages/AuthPages/AuthPage/AuthPage";
import ContactUs from "../pages/ContactUs/ContactUs";
import HomeNewPage from "../pages/HomeNewPage/HomeNewPage";
import HomePage from "../pages/HomePage/HomePage";
import JoinPage from "../pages/JoinPage/JoinPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import GearDetails from "../pages/OnBoarding/GearDetails/GearDetails";
import OnBoardingSuccess from "../pages/OnBoarding/OnBoardingSuccess/OnBoardingSuccess";
import PersonalDetails from "../pages/OnBoarding/PersonalDetails/PersonalDetails";
import PortfolioUpload from "../pages/OnBoarding/Portfolio/PortfolioUpload";
import SkillDetails from "../pages/OnBoarding/SkillDetails/SkillDetails";
import TimingPreference from "../pages/OnBoarding/TimingPreference/TimingPreference";
import OrderStatus from "../pages/OrderStatus/OrderStatus";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RefundPage from "../pages/RefundPage/RefundPage";
import ReservationDetailsPage from "../pages/ReservationDetailsPage/ReservationDetailsPage";
import ReservationPage from "../pages/ReservationPage/ReservationPage";
import SearchServicePage from "../pages/SearchServicePage/SearchServicePage";
import SelectSlotPage from "../pages/SelectSlotPage/SelectSlotPage";
import ServiceDetailsPage from "../pages/ServiceDetailsPage/ServiceDetailsPage";
import ServiceList from "../pages/ServiceList/ServiceList";
import SummaryPage from "../pages/SummaryPage/SummaryPage";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import UserAddress from "../pages/UserAddress/UserAddress";
import UserNotificationPage from "../pages/UserNotificationPage/UserNotificationPage";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import { AUTH_TOKEN } from "../utils/CookieConstants";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = Cookies.get(AUTH_TOKEN);
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/auth" />;
};

class AppRoute extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<HomeNewPage />} />
        <Route path="/home-partner" element={<HomePage />} />
        <Route path="/search-service" element={<SearchServicePage />} />
        <Route path="/user-profile" element={<UserProfilePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/join" element={<JoinPage />} />
        <Route path="/personal-details" element={<PersonalDetails />} />
        <Route path="/skill-details" element={<SkillDetails />} />
        <Route path="/gear-details" element={<GearDetails />} />
        <Route path="/timing-preference" element={<TimingPreference />} />
        <Route path="/portfolio-upload" element={<PortfolioUpload />} />
        <Route path="/onboarding-success" element={<OnBoardingSuccess />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cancellation" element={<RefundPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/delete-account" element={<AccountDeletionPage />} />
        <Route path="/services/:id?" element={<ServiceList />} />
        <Route path="/service-details/:id?" element={<ServiceDetailsPage />} />
        <Route path="/summary" element={<SummaryPage />} />
        <Route path="/select-slot" element={<SelectSlotPage />} />
        <Route
          path="/order-status"
          element={<PrivateRoute element={OrderStatus} />}
        />
        <Route
          path="/reservation-details/:id?"
          element={<PrivateRoute element={ReservationDetailsPage} />}
        />
        <Route
          path="/reservations"
          element={<PrivateRoute element={ReservationPage} />}
        />
        <Route
          path="/user-address"
          element={<PrivateRoute element={UserAddress} />}
        />
        <Route
          path="/user-notifications"
          element={<PrivateRoute element={UserNotificationPage} />}
        />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    );
  }
}

export default AppRoute;
