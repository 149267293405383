import React from "react";
import { SlArrowLeft } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import "./PageHeader.css";

const PageHeader = ({ title, route }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (route) {
      navigate(route, { replace: true });
    } else {
      navigate(-1, { replace: true });
    }
  };

  return (
    <div className="page-header-container" onClick={handleBackClick}>
      <div className="container page-header">
        <SlArrowLeft className="page-chevron-icon" size={18} />
        <h4 className="page-title">{title}</h4>
      </div>
    </div>
  );
};

export default PageHeader;
