import React from "react";

const PortfolioControlCard = ({ setConsentModal }) => {
  return (
    <div className="info-card">
      <div className="title-container">
        <h5 className="title">Portfolio control</h5>
        <span
          className="edit-text"
          style={{ cursor: "pointer" }}
          onClick={setConsentModal}
        >
          Update preference
        </span>
      </div>
      <h6 className="my-2" style={{ fontWeight: "bold" }}>
        Portfolio inclusion
      </h6>
      <p>
        You’re saving an additional 10% on this order. Some of your best files
        may be used to showcase on our portfolio.{" "}
      </p>
    </div>
  );
};

export default PortfolioControlCard;
