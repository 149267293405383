import React, { useEffect, useState } from "react";
import { GoBriefcase, GoHome, GoLocation } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import NewHeader from "../../components/common/NewHeader/NewHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import UserAddressDrawer from "./components/UserAddressDrawer";
import "./UserAddress.css";

const UserAddress = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(false);
  const [deletingAddress, setDeletingAddress] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await apiClient.request(AppURL.userLocationUrl, "GET");
        if (response && response.shootLocation) {
          setLocations(response.shootLocation);
        }
      } catch (error) {
        console.error("Error fetching address", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAddress();
  }, []);

  const setLocationDefault = async (locationId) => {
    const currentDefaultLocation = locations.find(
      (location) => location.defaultLocation
    );
    if (currentDefaultLocation && currentDefaultLocation._id === locationId) {
      toast.info("This location is already set as default");
      return;
    }
    if (defaultLocation) {
      toast.error("Please wait while we set the default location");
      return;
    }
    try {
      setDefaultLocation(true);
      const response = await apiClient.request(
        `${AppURL.userLocationDefaultUrl}/${locationId}`,
        "PUT"
      );
      if (response && response.error === false) {
        const updatedLocations = locations.map((location) => {
          location.defaultLocation = location._id === locationId;
          return location;
        });
        setLocations(updatedLocations);
        toast.success("Default location set successfully");
      }
    } catch (error) {
      toast.error("Error setting default location");
      console.error("Error fetching address", error);
    } finally {
      setDefaultLocation(false);
    }
  };

  const deleteAddress = async (locationId) => {
    if (deletingAddress) {
      toast.error("Please wait while we delete the address");
      return;
    }
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      setDeletingAddress(true);
      const response = await apiClient.request(
        `${AppURL.userLocationUrl}/${locationId}`,
        "DELETE"
      );
      if (response && response.error === false) {
        const updatedLocations = locations.filter(
          (location) => location._id !== locationId
        );
        setLocations(updatedLocations);
        toast.success("Address deleted successfully");
      }
    } catch (error) {
      toast.error("Error deleting address");
      console.error("Error fetching address", error);
    } finally {
      setDeletingAddress(false);
    }
  };

  const getIcon = (locationType) => {
    switch (locationType) {
      case "Home":
        return <GoHome size={24} />;
      case "Work":
        return <GoBriefcase size={24} />;
      default:
        return <GoLocation size={24} />;
    }
  };

  const handleSaveSuccess = (newLocation) => {
    const updatedLocations = locations.map((location) =>
      location._id === newLocation._id
        ? newLocation
        : { ...location, defaultLocation: false }
    );

    if (editingLocation) {
      setLocations(updatedLocations);
    } else {
      setLocations([...updatedLocations, newLocation]);
    }

    setEditingLocation(null);
  };

  const handleEditClick = (location) => {
    setEditingLocation(location);
    setShowDrawer(true);
  };

  if (loading) {
    return (
      <>
        <NewHeader />
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <NewHeader />

      <div className="user-address-container container">
        <div className="user-address-card-header">
          <h2>User Addresses</h2>
          <span
            className="user-address-add-new user-address-text-orange"
            onClick={() => {
              setEditingLocation(null);
              setShowDrawer(true);
            }}
          >
            Add New Address
          </span>
        </div>
        <div className="user-address-row">
          {locations.map((location, index) => (
            <div key={index} className="user-address-col">
              <div
                className={`user-address-card ${
                  location.defaultLocation ? "user-address-border-dark" : ""
                }`}
              >
                <div className="user-address-card-body">
                  <div className="user-address-row">
                    <div className="user-address-icon-col">
                      {getIcon(location.locationType)}
                    </div>
                    <div className="user-address-text-col">
                      <div className="user-address-card-title">
                        {location.locationType}
                      </div>
                      <div className="user-address-card-text">
                        {location.shootLocation}
                      </div>
                      <div>
                        <button
                          className="user-address-button user-address-text-orange"
                          style={{ marginRight: "40px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(location);
                          }}
                        >
                          EDIT
                        </button>
                        <button
                          className="user-address-button user-address-text-orange"
                          style={{ marginRight: "40px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setLocationDefault(location._id);
                          }}
                        >
                          SET AS DEFAULT
                        </button>
                        <button
                          className="user-address-button user-address-text-orange"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAddress(location._id);
                          }}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <UserAddressDrawer
        show={showDrawer}
        handleClose={() => setShowDrawer(false)}
        onSaveSuccess={handleSaveSuccess}
        editingLocation={editingLocation}
      />
      <ToastContainer />
    </>
  );
};

export default UserAddress;
