import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import PageHeader from "../../components/common/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { CITY } from "../../utils/CookieConstants";
import ServiceCard from "../ServiceList/components/ServiceCard";
import "./SearchServicePage.css";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const SearchServicePage = () => {
  const [query, setQuery] = useState("");
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("Bengaluru");

  useEffect(() => {
    const city = Cookies.get(CITY);
    if (city) {
      setCity(city);
    }
  }, []);

  const fetchServices = useCallback(
    async (searchQuery) => {
      setLoading(true);
      try {
        const response = await apiClient.request(
          `${AppURL.serviceSearchUrl}?searchQuery=${searchQuery}&city=${city}`,
          "GET"
        );
        if (response && response.services) {
          setServices(response.services);
        } else {
          console.error("Failed to fetch services");
        }
      } catch (error) {
        console.error("Error fetching services", error);
      } finally {
        setLoading(false);
      }
    },
    [city]
  );

  const debouncedFetchServices = useMemo(
    () => debounce(fetchServices, 500),
    [fetchServices]
  );

  useEffect(() => {
    if (query) {
      debouncedFetchServices(query);
    } else {
      setServices([]);
    }
  }, [query, debouncedFetchServices]);

  return (
    <>
      <PageHeader title="Search Services" />
      <div className="container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search for service"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <FaSearch className="search-icon" />
        </div>
        {loading && <LoadingSpinner />}
        {!loading && services.length === 0 && query && (
          <div className="no-services">No services found</div>
        )}
        <div className="service-list">
          {services.map((service) => (
            <ServiceCard
              key={service._id}
              title={service.name}
              subtitle={service.description}
              price={`₹${service.startingFrom}`}
              thumbnailImage={service.thumbnailImage}
              service={service}
              path={`/service-details/${service._id}`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchServicePage;
