import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import "./PackageCard.css";

const PackageCard = ({
  packageColor,
  name,
  packageDetails,
  salePrice,
  regularPrice,
  shootDuration,
  handlePackageContinue,
}) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="package-card" style={{ backgroundColor: packageColor }}>
      <div className="package-card-header">
        <h6 className="package-card-title">{name}</h6>
        <div className="package-card-divider-vertical"></div>
        <h6 className="package-card-subtitle">
          {shootDuration} {shootDuration === 1 ? "hour" : "hours"} session
        </h6>
      </div>
      <div className="package-card-divider-horizontal"></div>
      <ul className="package-card-details">
        {packageDetails &&
          packageDetails
            .slice(0, showAll ? packageDetails.length : 6)
            .map((detail, index) => (
              <li key={index} className="package-card-detail">
                {detail.available ? (
                  <FaCheck size={12} className="detail-icon tick" />
                ) : (
                  <FaTimes size={12} className="detail-icon cross" />
                )}
                {detail.name}
              </li>
            ))}
      </ul>
      {(packageDetails && packageDetails.length) > 6 && (
        <div className="package-card-toggle" onClick={toggleShowAll}>
          {showAll ? "See less" : "See more"}
        </div>
      )}
      <div className="package-card-prices">
        <span className="package-card-regular-price">₹{regularPrice}</span>
        <span className="package-card-sale-price">₹{salePrice}</span>
      </div>
      <button className="package-card-button" onClick={handlePackageContinue}>
        Continue
      </button>
    </div>
  );
};

export default PackageCard;
