// orderActions.js
import {
  ORDER_SAVE_BOOKING_DETAILS,
  ORDER_SAVE_VOLUME_DISCOUNT,
  ORDER_SESSION_SAVE_DETAILS,
} from "../types/orderTypes";

export const saveOrderSession = (
  serviceId,
  packageData,
  shootDuration,
  serviceData
) => {
  return (dispatch) => {
    dispatch({
      type: ORDER_SESSION_SAVE_DETAILS,
      payload: { serviceId, packageData, shootDuration, serviceData },
    });
  };
};

export const saveBookingDetails = (bookingDate, timeSlot) => {
  return (dispatch) => {
    dispatch({
      type: ORDER_SAVE_BOOKING_DETAILS,
      payload: { bookingDate, timeSlot },
    });
  };
};

export const saveVolumeDiscount = (
  checkoutInfo,
  selectedCounter,
  volumeDiscount,
  priceWithVolumeDiscount
) => {
  return (dispatch) => {
    dispatch({
      type: ORDER_SAVE_VOLUME_DISCOUNT,
      payload: {
        checkoutInfo,
        selectedCounter,
        volumeDiscount,
        priceWithVolumeDiscount,
      },
    });
  };
};
