import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import onboardingReducers from "../reducers/onboardingReducers";
import orderReducers from "../reducers/orderReducers";
import userReducers from "../reducers/userReducers";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["onboardingData", "userData"],
};

const rootReducer = combineReducers({
  onboardingData: onboardingReducers,
  orderData: orderReducers,
  userData: userReducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let persistor = persistStore(store);

export { persistor, store };
