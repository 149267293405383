import PropTypes from "prop-types";
import React from "react";
import "./ServiceCounterContainer.css";

const ServiceCounterContainer = ({
  title,
  selectedNumber,
  minNumber,
  discountPercentage,
  totalCount,
  discountInfo,
  onValueChanged,
}) => {
  return (
    <div className="service-counter-container">
      <h2 className="title">{title}</h2>
      <div className="counter-wrapper">
        <div className="counter-list">
          {[...Array(totalCount)].map((_, index) => {
            const number = minNumber + index;
            const isSelected = number === selectedNumber;
            return (
              <div
                key={number}
                className={`counter-item ${isSelected ? "selected" : ""}`}
                onClick={() => onValueChanged(number)}
              >
                {number}
              </div>
            );
          })}
        </div>
        {selectedNumber !== minNumber && discountPercentage !== null && (
          <div className="discount-info">
            {discountInfo} {discountPercentage.toFixed(2)}%
          </div>
        )}
      </div>
    </div>
  );
};

ServiceCounterContainer.propTypes = {
  title: PropTypes.string.isRequired,
  selectedNumber: PropTypes.number,
  minNumber: PropTypes.number.isRequired,
  discountPercentage: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  discountInfo: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired,
};

export default ServiceCounterContainer;
