import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
import { ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const OrderStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [status, setStatus] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const order_id = queryParams.get("order_id");

    const handlePaymenResponse = async (order_id) => {
      try {
        const res = await apiClient.request(
          AppURL.bookingOrderStatusUrl,
          "POST",
          {
            orderId: order_id,
          }
        );

        setLoading(false);
        if (res && res.message === "success") {
          navigate("/reservations");
        } else {
          navigate("/");
        }
      } catch (error) {
        // console.error("Error fetching order status:", error);
        setLoading(false);
        navigate("/");
      }
    };

    if (order_id) {
      handlePaymenResponse(order_id);
    }
  }, [location, navigate]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          {loading && <LoadingSpinner />}
          {/* {!loading && status === "success" && (
            <Alert variant="success">Booking Done Successfully</Alert>
          )}
          {!loading && status === "error" && (
            <Alert variant="danger">Booking Failed</Alert>
          )} */}
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default OrderStatus;
