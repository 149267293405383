import React, { useState } from "react";
import "./BookingPreferenceModal.css";

const BookingPreferenceModal = ({ isOpen, onClose, onProceed }) => {
  const [selectedOption, setSelectedOption] = useState("auto");

  if (!isOpen) return null;

  return (
    <div className="booking-modal-overlay">
      <div className="booking-modal-content">
        <div className="booking-modal-header">Booking Preference</div>
        <hr className="booking-modal-divider" />
        <div className="booking-radio-group">
          <label>
            <input
              type="radio"
              name="bookingPreference"
              value="auto"
              checked={selectedOption === "auto"}
              onChange={() => setSelectedOption("auto")}
            />
            <span style={{ fontWeight: "bold", color: "black" }}>
              Auto assign
            </span>
          </label>
          <div className="booking-modal-description">
            All teams have been vetted for quality and expertise by the Gratif
            team
          </div>
          <label>
            <input
              type="radio"
              name="bookingPreference"
              value="manual"
              disabled
            />
            <span style={{ fontWeight: "bold", color: "grey" }}>
              Choose a team manually (Coming soon)
            </span>
          </label>
        </div>
        <button className="booking-modal-proceed-button" onClick={onProceed}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default BookingPreferenceModal;
