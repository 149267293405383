import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <SyncLoader color="#ff7f24" />
    </div>
  );
};

export default LoadingSpinner;
