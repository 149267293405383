import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt, FaReceipt, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import PageHeader from "../../components/common/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { formatDateFromString } from "../../utils/DateUtils";
import { replaceUnderscores } from "../../utils/helper";
import CancelBookingModal from "./components/CancelBookingModal";
import "./ReservationDetailsPage.css";

const steps = [
  "Booked",
  "Professional assigned",
  "Shoot completed",
  "RAW files/Soft copies uploaded",
  "Edited files are ready to download",
  "Completed",
];

const statusToStepIndex = {
  BOOKED: 0,
  PARTNER_ASSIGNED: 1,
  SHOOT_COMPLETED: 2,
  RAW_UPLOADED: 3,
  EDITED_READY: 4,
  PROJECT_COMPLETED: 5,
};

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <div>
      {completed || active ? (
        <CheckCircleIcon style={{ color: "black" }} />
      ) : (
        <CheckCircleIcon style={{ color: "grey" }} />
      )}
    </div>
  );
};

const isCancellationAllowed = (bookingDate, status, bookingId) => {
  if (!bookingId) {
    return false;
  }
  if (bookingDate && (status === "BOOKED" || status === "PARTNER_ASSIGNED")) {
    try {
      const bookingDateTime = new Date(bookingDate);
      const now = new Date();
      const difference = bookingDateTime - now;
      return difference > 48 * 60 * 60 * 1000; // 48 hours in milliseconds
    } catch (e) {
      console.error("Error parsing date:", e);
    }
  }
  return false;
};

const ReservationDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(!location?.state?.reservation);
  const [reservationDetails, setReservationDetails] = useState(
    location?.state?.reservation
  );
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    const fetchReservationDetails = async () => {
      try {
        const response = await apiClient.request(
          `${AppURL.bookingUrl}/${id}`,
          "GET"
        );
        if (response) {
          setReservationDetails(response);
        } else {
          console.error("Failed to fetch reservation details");
        }
      } catch (error) {
        console.error("Error fetching reservation details", error);
      } finally {
        setLoading(false);
      }
    };

    if (!reservationDetails && id) {
      fetchReservationDetails();
    }
  }, [id, reservationDetails]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmCancellation = async (reason) => {
    if (isCancelling) {
      toast.error("Cancelling booking, please wait...");
      return;
    }
    setIsCancelling(true);
    try {
      const response = await apiClient.request(
        `${AppURL.bookingCancelUrl}`,
        "POST",
        {
          bookingId: reservationDetails._id,
          cancellationReason: reason,
        }
      );
      if (response) {
        navigate("/");
      } else {
        console.error("Failed to cancel booking");
      }
    } catch (error) {
      console.error("Error cancelling booking", error);
    } finally {
      setIsCancelling(false);
      handleCloseModal();
    }
  };

  const handleDownloadReceipt = () => {
    if (reservationDetails.receiptUrl) {
      window.open(reservationDetails.receiptUrl, "_blank");
    }
  };

  const handleViewRawFiles = () => {
    if (reservationDetails.rawFilesLink) {
      window.open(reservationDetails.rawFilesLink, "_blank");
    }
  };

  const handleViewEditedFiles = () => {
    if (reservationDetails.finalFilesLink) {
      window.open(reservationDetails.finalFilesLink, "_blank");
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!reservationDetails) {
    return <div>No reservation details found</div>;
  }

  const activeStep = statusToStepIndex[reservationDetails.status] || 0;

  return (
    <>
      <PageHeader title="Reservations" />
      <div className="container">
        <div className="reservation-details-card">
          <div className="reservation-details-header">
            <button
              className="reservation-details-button"
              onClick={handleDownloadReceipt}
              disabled={!reservationDetails.receiptUrl}
            >
              <FaReceipt />
              {reservationDetails.receiptUrl
                ? "Download Receipt"
                : "Receipt will be available soon"}
            </button>
            {!reservationDetails.finalFilesLink &&
              reservationDetails.rawFilesLink && (
                <button
                  className="reservation-link-button"
                  onClick={handleViewRawFiles}
                >
                  View soft copies
                </button>
              )}
            {reservationDetails.finalFilesLink && (
              <button
                className="reservation-link-button"
                onClick={handleViewEditedFiles}
              >
                View edited files
              </button>
            )}
            {isCancellationAllowed(
              reservationDetails.bookingDate,
              reservationDetails.status,
              reservationDetails._id
            ) && (
              <button
                className="reservation-details-button"
                onClick={handleOpenModal}
                disabled={isCancelling}
              >
                <FaTimes /> {isCancelling ? "Cancelling..." : "Cancel Booking"}
              </button>
            )}
          </div>
          <div className="reservation-details-timeline">
            <div className="stepper-container">
              {reservationDetails.status === "PROJECT_CANCELLED" ? (
                <Stepper activeStep={1} alternativeLabel>
                  <Step key="Booked" completed>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      sx={{
                        "& .MuiStepIcon-root.Mui-active": {
                          color: "black",
                        },
                        "& .MuiStepIcon-root.Mui-completed": {
                          color: "black",
                        },
                      }}
                    >
                      Booked
                    </StepLabel>
                  </Step>
                  <Step key="Project Cancelled" completed>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      sx={{
                        "& .MuiStepIcon-root.Mui-active": {
                          color: "black",
                        },
                        "& .MuiStepIcon-root.Mui-completed": {
                          color: "black",
                        },
                      }}
                    >
                      Cancelled
                    </StepLabel>
                  </Step>
                </Stepper>
              ) : (
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label} completed={index <= activeStep}>
                      <StepLabel
                        StepIconComponent={CustomStepIcon}
                        sx={{
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "black",
                          },
                          "& .MuiStepIcon-root.Mui-completed": {
                            color: "black",
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </div>
          </div>
          <div className="reservation-details-main">
            <div className="reservation-details-left">
              <h3 className="reservation-details-title">
                {reservationDetails.serviceName}
              </h3>
              <div className="reservation-details-content">
                <p className="reservation-details-text">
                  <span className="label">Booking ID:</span>
                  <span className="value">{reservationDetails.customId}</span>
                </p>
                <p className="reservation-details-text">
                  <span className="label">Status:</span>
                  <span className="value">
                    {replaceUnderscores(reservationDetails.status)}
                  </span>
                </p>
                <div className="reservation-details-divider"></div>
                <p className="reservation-details-text">
                  <span className="label">Date:</span>
                  <span className="value">
                    {formatDateFromString(reservationDetails.bookingDate)}
                  </span>
                  <span className="vertical-divider"></span>
                  <span className="label">Starting at:</span>
                  <span className="value">{reservationDetails.timeSlot}</span>
                </p>
                <div className="reservation-details-divider"></div>
                <p className="reservation-details-text">
                  <FaMapMarkerAlt />
                  <span className="value" style={{ fontWeight: "bold" }}>
                    {reservationDetails.locationName}
                  </span>
                </p>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="reservation-details-right">
              <h3 className="reservation-details-title">Payment Details</h3>
              <div className="reservation-details-content">
                {reservationDetails.costBreakdown.map((item, index) => (
                  <p className="reservation-details-text" key={index}>
                    <span className="label">{item.title}:</span>
                    <span className="value">{item.price}</span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CancelBookingModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmCancellation}
      />
      <ToastContainer />
    </>
  );
};

export default ReservationDetailsPage;
