import React, { useEffect, useState } from "react";
import apiClient from "../../api/ApiClients";
import AppURL from "../../api/AppUrls";
import PageHeader from "../../components/common/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ReservationCard from "./components/ReservationCard";
import "./ReservationPage.css";

const ReservationPage = () => {
  const [activeTab, setActiveTab] = useState("ongoing");
  const [loading, setLoading] = useState(true);
  const [ongoingReservations, setOngoingReservations] = useState([]);
  const [pastReservations, setPastReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await apiClient.request(
          AppURL.bookingStatusUrl,
          "GET"
        );
        if (response.message === "success") {
          setOngoingReservations(response.ongoingBookings);
          setPastReservations(response.completedBookings);
        } else {
          console.error("Failed to fetch reservations");
        }
      } catch (error) {
        console.error("Error fetching reservations", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    switch (activeTab) {
      case "ongoing":
        return ongoingReservations.length > 0 ? (
          <div className="reservation-card-container">
            {ongoingReservations.map((reservation, index) => (
              <ReservationCard key={index} reservation={reservation} />
            ))}
          </div>
        ) : (
          <div>No ongoing reservations</div>
        );
      case "past":
        return pastReservations.length > 0 ? (
          <div className="reservation-card-container">
            {pastReservations.map((reservation, index) => (
              <ReservationCard key={index} reservation={reservation} />
            ))}
          </div>
        ) : (
          <div>No past reservations</div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PageHeader title="Home" route="/" />
      <div className="container">
        <div className="tabs">
          <div
            className={`tab ${activeTab === "ongoing" ? "active" : ""}`}
            onClick={() => setActiveTab("ongoing")}
          >
            Ongoing projects / Active
          </div>
          <div
            className={`tab ${activeTab === "past" ? "active" : ""}`}
            onClick={() => setActiveTab("past")}
          >
            Past reservations
          </div>
        </div>
        <div className="tab-content">{renderContent()}</div>
      </div>
    </>
  );
};

export default ReservationPage;
