import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./SectionTitle.css";

const SectionTitle = ({ title, path, services }) => {
  return (
    <div className="section-title">
      <span>{title}</span>

      <Link to={path} state={{ services }} className="see-all">
        See All
      </Link>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
};

export default SectionTitle;
