import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../../../../api/ApiClients";
import AppURL from "../../../../api/AppUrls";
import { CITY } from "../../../../utils/CookieConstants";
import "./LeftHomeDrawer.css";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const LeftHomeDrawer = ({ isOpen, onClose }) => {
  const [locations, setLocations] = useState([]);
  const [placeName, setPlaceName] = useState("");
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  const fetchLocation = useCallback(
    async (searchQuery) => {
      try {
        const response = await apiClient.request(
          AppURL.locationAutocompleteUrl,
          "POST",
          {
            placeName: searchQuery,
            sessionId: sessionId,
          }
        );
        if (response && response.places) {
          setLocations(response.places);
        }
      } catch (error) {
        console.error("Error fetching service details", error);
      }
    },
    [sessionId]
  );

  const checkCityAvaibility = async (lat, lng, name) => {
    if (lat && lng) {
      try {
        const url = `${AppURL.checkCityAvaaibility}?lat=${lat}&lng=${lng}`;
        const response = await apiClient.request(url, "GET");
        if (response) {
          if (response.cityAvailable && response.cityName) {
            Cookies.set(CITY, response.cityName);
            window.location.reload();
          } else if (name) {
            toast.error(`Sorry, Gratif is not available in ${name}`);
          } else {
            toast.error("Sorry, Gratif is not available in this location");
          }
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.error("Error fetching service details", error);
      }
    }
  };

  const debouncedFetchLocation = useMemo(
    () => debounce(fetchLocation, 300),
    [fetchLocation]
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPlaceName(value);
    if (value.length > 2) {
      debouncedFetchLocation(value);
    } else if (value.length === 0) {
      setLocations([]);
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
          checkCityAvaibility(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            toast.error("User denied the request for location.");
          } else {
            toast.error("Error getting current location");
          }
          console.error("Error getting current location", error);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      {isOpen && <div className="backdrop" onClick={onClose}></div>}
      <div className={`left-home-drawer ${isOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <FaTimes className="close-icon" onClick={onClose} />
        </div>
        <div className="drawer-content">
          <input
            type="text"
            className="search-input"
            placeholder="Search for area, street name..."
            value={placeName}
            onChange={handleInputChange}
          />
          {locations.length === 0 && (
            <div
              className="location-container"
              onClick={handleGetCurrentLocation}
            >
              <FaLocationArrow className="location-icon" />
              <div className="location-text">
                <span className="get-location">Get current location</span>
                <span className="using-gps">Using GPS</span>
              </div>
            </div>
          )}
          <div className="location-results">
            {locations.map((location) => (
              <div
                key={location.place_id}
                className="location-item"
                onClick={() =>
                  checkCityAvaibility(
                    location.lat,
                    location.lng,
                    location.long_name
                  )
                }
              >
                <span>{location.description}</span>
                <span>{location.long_name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LeftHomeDrawer;
