import React from "react";
import { FaTimes } from "react-icons/fa";
import "./CouponAppliedModal.css";

const CouponAppliedModal = ({ isOpen, onClose, couponCode, savedAmount }) => {
  if (!isOpen) return null;

  return (
    <div className="coupon-modal-overlay">
      <div className="coupon-modal-content">
        <button className="coupon-modal-close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="coupon-modal-message">
          <p>"{couponCode}" applies!</p>
          <p>
            <strong>You have saved {savedAmount} with this coupon</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponAppliedModal;
