import React from "react";
import logo from "../../../assets/images/gratif_logo.png";

const AuthLeftSide = () => {
  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <div style={styles.divider}></div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
  },
  logo: {
    height: "15rem",
  },
  divider: {
    height: "100%",
    width: "1px",
    backgroundColor: "#F9F9F9",
    position: "absolute",
    right: 0,
  },
};

export default AuthLeftSide;
